<!-- @Author: chengyuzhang -->
<!-- @Date: 2022-4-15 18:58:54 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 14:04:26 -->

<template>
    <!-- eslint-disable max-len -->
    <div class="app-store-button">
        <div
            v-if="videoVisible"
            ref="videoModal"
            class="video-modal"
            @touchmove.prevent
        >
            <div class="wrapper">
                <div class="video-modal-content">
                    <video
                        ref="myVideo"
                        :src="videoUrl"
                        controls
                        preload
                        x-webkit-airplay="true"
                        x5-video-player-type="h5"
                        x5-video-orientation="portraint"
                        x5-playsinline="true"
                        webkit-playsinline="true"
                        playsinline="true"
                        poster="./img/poster.png"
                    />
                </div>
                <img
                    src="./img/close.png"
                    class="btn-close"
                    @click="closeVideoModal"
                >
            </div>
        </div>

        <img
            v-if="type === 'video'"
            class="video-btn"
            :src="videoActive ? videoBtnActive : videoBtn"
            @mouseenter="videoActive = true"
            @mouseleave="videoActive = false"
            @click="handleVideoClick"
        >

        <div
            v-if="type === 'google'"
            class="channel-btn google-btn"
        >
            <img
                src="./img/google.png"
                class="channel"
                @mouseenter="handleAppCodeVisible(true)"
                @mouseleave="handleAppCodeVisible(false)"
                @click="handleClick"
            >
        </div>

        <div
            v-if="type === 'apple'"
            class="channel-btn apple-btn"
        >
            <img
                src="./img/apple.png"
                class="channel"
                @mouseenter="handleAppCodeVisible(true)"
                @mouseleave="handleAppCodeVisible(false)"
                @click="handleClick"
            >
        </div>

        <div
            v-if="type === 'apple-w'"
            class="channel-btn apple-w-btn"
        >
            <img
                src="./img/apple-w.png"
                class="channel"
                @mouseenter="handleAppCodeVisible(true)"
                @mouseleave="handleAppCodeVisible(false)"
                @click="handleClick"
            >
        </div>

        <div
            v-show="codeVisible"
            class="code"
            :class="codePosition"
        >
            <img src="./img/download-code.svg">
        </div>
    </div>
</template>

<script>
import {ScreenMobile} from '../../constant/page-config';
import videoBtnActive from './img/video-button-active.png';
import videoBtn from './img/video-button.png';

const StoreMap = {
    'google': 'https://play.google.com/store/apps/details?id=com.fintopia.idnEasycash.google',
    'apple': 'https://apps.apple.com/id/app/id1435044790',
    'apple-w': 'https://apps.apple.com/id/app/id1435044790'
};

export default {
    name: 'AppStoreButton',

    inject: ['uploadClickEvent'],

    props: {
        type: {
            type: String,
            default: 'google'
        },
        codePosition: {
            type: String,
            default: 'top'
        },
        uniqueId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            codeVisible: false,
            isMobile: false,
            videoVisible: false,
            videoUrl: 'https://ec-cdn.easycash.id/upload/admin/lmF6Lzo6DSGtXEIHV0NoFyykDcQb.mp4',
            videoActive: false,
            videoBtn,
            videoBtnActive
        };
    },

    mounted() {
        this.isMobile = window.innerWidth < ScreenMobile;
    },

    methods: {
        handleAppCodeVisible(status) {
            const {isMobile, type, uniqueId} = this;

            if (isMobile) return;
            if (status) {
                this.uploadClickEvent(`${type}Qr${uniqueId}`);
            }

            this.codeVisible = status;
        },

        handleClick() {
            const {type, uniqueId} = this;
            this.uploadClickEvent(`${type}Download${uniqueId}`);
            window.open(StoreMap[type]);
        },

        handleVideoClick() {
            this.videoVisible = true;
            this.$nextTick(() => {
                const html = document.querySelector('html');
                const body = document.querySelector('body');
                html.classList.add('prevent-scroll');
                body.appendChild(this.$refs.videoModal);
                if (this.$refs.myVideo) {
                    this.$refs.myVideo.load();
                }
            });
        },

        closeVideoModal() {
            const html = document.querySelector('html');
            html.classList.remove('prevent-scroll');
            this.videoVisible = false;
        }
    }
};
</script>

<style lang="scss">
@import "../../style/bootscrap-variables";

.prevent-scroll {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video-modal {
    position: fixed;
    padding: 0.1rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .wrapper {
        position: relative;
        max-width: 8rem;
        width: 50%;
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-md) {
            width: 90%;
        }

        .btn-close {
            position: absolute;
            width: 0.25rem;
            height: auto;
            top: -0.35rem;
            right: 0;
            cursor: pointer;
        }

        .video-modal-content video {
            max-width: 100%;
            max-height: 100%;
            border-radius: 0.1rem;
            object-fit: cover;
        }
    }
}
</style>

<style lang="scss" scoped>
@import "../../style/style";

.app-store-button {
    position: relative;

    svg {
        width: 100%;
        height: auto;

        &.google .hover {
            fill: #009f65;
        }

        &.apple .hover {
            fill: #404040;
        }
    }

    .code {
        position: absolute;
        transform: translateX(-50%);
        padding: 0.25rem;
        box-shadow: -0.15rem 0 0.4rem rgba(8, 20, 32, 0.2);
        border-radius: 0.2rem;
        background: #fff;
        z-index: 10;

        &.top {
            bottom: 100%;
            left: 50%;
        }

        &.bottom {
            top: 100%;
            left: 50%;
        }

        img {
            width: 1.9rem;
            height: 1.9rem;
        }
    }

    .video-btn {
        width: 0.465rem;
        height: 0.465rem;
        margin-right: 0.15rem;
        cursor: pointer;

        @media screen and (max-width: $screen-md) {
            width: 0.4rem;
            height: 0.4rem;
            margin-right: 0.1rem;
        }
    }

    .channel-btn {
        width: 1.65rem;
        height: 0.465rem;
        border-radius: 0.08rem;
        margin-right: 0.15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @media screen and (max-width: $screen-md) {
            width: 1.4rem;
            height: 0.4rem;
            border-radius: 0.04rem;
            margin-right: 0.05rem;
        }
    }

    &:last-child {
        .channel-btn {
            margin-right: 0 !important;
        }
    }

    .google-btn {
        background: #3fca58;

        &:hover,
        &:active {
            background: #079721;
        }
    }

    .apple-btn {
        background: #000;

        &:hover,
        &:active {
            background: #333;
        }
    }

    .apple-w-btn {
        background: #fff;

        &:hover,
        &:active {
            background: #f1f1f1;
        }
    }

    .channel {
        width: 1.38rem;
        height: 0.3rem;

        @media screen and (max-width: $screen-md) {
            width: 1.15rem;
            height: 0.245rem;
        }
    }
}
</style>
